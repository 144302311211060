enum Device { UNKNOWN, IOS, ANDROID, DESKTOP };

export const CLIENT_ID = '699589375244-e4h42nptp6j3lqqp8n19kk3n9gasbu0p.apps.googleusercontent.com';

export const isMobileDevice = () => {
    const os = getMobileOperatingSystem();
    return os === Device.ANDROID || os === Device.IOS;
}

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return Device.DESKTOP;
    }

    if (/android/i.test(userAgent)) {
        return Device.ANDROID;
    }

    if ([
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || navigator.userAgent.includes("Mac") && "ontouchend" in document) {
        return Device.IOS;
    }

    return Device.UNKNOWN;
}

export function redirectToStore() {
    const os = getMobileOperatingSystem();
    if (os === Device.ANDROID) {
        window.location.href = "https://play.google.com/store/apps/details?id=com.peloton.ride.together";
    } else if (os === Device.IOS) {
        window.location.href = "https://apps.apple.com/us/app/proto-ride-together/id1566912582";
    } else {
        alert('Works only on mobile device');
    }
}

export const validations = {
    isNameValid: (name: string) => {
        const regex = /^[\u0590-\u05FFa-zA-Z0-9_\s]{3,30}$/;
        return name.length > 1 && regex.test(name);
    },
    isEmailValid: (email: string) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    },
    isPasswordValid: (password: string) => {
        const regex = /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,10}$/;
        return regex.test(password);
    }
};

export const ErrorTypes = {
    unauthorized: '401',
    forbidden: '403',
    notFound: '404',
    timeout: '408',
    custom_1: '419',
    custom_2: '420',
    serverInternal: '500',
    serverUnavailable: '503',
    retry: 'RETRY',
    unknown: 'UNKNOWN',
}

export const PROTO_SUPPORT_MAIL = "proto.ride.together@gmail.com";


// ------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------

export enum Mode { DEV, PROD, TEST };

export const currentMode: Mode = Mode.DEV;

export function getUrl() {
    switch (currentMode) {
        case Mode.DEV:
            return 'http://localhost:3000';
        case Mode.PROD:
            return 'https://calm-dawn-88241.herokuapp.com';
    }
}

// ------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------

export const ACCESS_TOKEN_KEY = 'access-token';

