import { ACCESS_TOKEN_KEY, ErrorTypes, getUrl } from "./utils";

export enum LoginType { USERNAME_PASSWORD, GOOGLE, APPLE };

export type AuthenticationType = {
    username: string | null,
    password?: string,
    googleId?: string,
    loginType?: LoginType,
};

export const loginRequest = (loginDetails: AuthenticationType): Promise<{ accessToken: string }> => {
    return postRequest('users/login/', loginDetails);
}
export const deleteAccountRequest = (userId: string): Promise<void> => {
    return deleteRequest(`users/${userId}`);
}
export const getUserBasicRequest = async (): Promise<{ _id: string }> => {
    return await getRequest(`users/basic`);
}
export const googleLoginRequest = (idToken: string, username: string): Promise<{ accessToken: string } | null> => {
    return postRequest('users/auth/google/', { idToken, username });
}

// ------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------

const getHeader = async () => {
    var headers = new Headers();
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (accessToken) {
        headers.append('Authorization', 'Bearer ' + accessToken);
    }
    headers.append('Content-type', 'application/json');
    return headers;
}

// ------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------

const postRequest = async (endPoint: string, json: object = {}): Promise<any> => await request('POST', endPoint, json);
const deleteRequest = async (endPoint: string, json: object = {}): Promise<any> => await request('DELETE', endPoint, json);
const getRequest = async (endPoint: string, json: object = {}): Promise<any> => await request('GET', endPoint, json);
// const putRequest = async (endPoint: string, json: object = {}): Promise<any> => await request('PUT', endPoint, json);

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';
async function request(method: Method, endPoint: string, json = {}, isFirstTime = true): Promise<any> {
    // console.info({ method, endPoint });
    const start = Date.now();
    var data;
    try {
        const headers = await getHeader();
        const requestOptions: RequestInit = { method, headers };
        if (method !== 'GET') {
            requestOptions['body'] = JSON.stringify(json);
        }
        const res = await fetch(`${getUrl()}/${endPoint}`, requestOptions);
        if (res.ok) {
            data = await res.json();
            console.log(`call "${endPoint}" took ${Date.now() - start} ms`);
            return data;
        } else {
            throw res.status;
        }
    }
    catch (status: any) {
        if (status === ErrorTypes.retry) {
            try {
                const res = await request(method, endPoint, json, false);
                return res;
            } catch (error: any) {
                throw error;
            }
        }
        else if (status === ErrorTypes.timeout) {
            if (isFirstTime) {
                try {
                    const res = await request(method, endPoint, json, false);
                    return res;
                } catch (error: any) {
                    throw error;
                }
            }
            else {
                throw status;
            }
        }
        else {
            const message = `Error (at ${endPoint}): ${status}`;
            throw status;
        }
    }
}
