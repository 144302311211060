import logo from '../assets/logo_light.png';

const Home = () => {
    const width = window.innerWidth > 991 ? '30vw' :
        window.innerWidth > 596 ? '40vw' : '50vw';
    return (
        <div className="App" style={{ minHeight: '94vh' }}>
            <img src={logo} style={{ width, objectFit: 'contain' }} alt='logo' />
        </div>
    );
}

export default Home;
