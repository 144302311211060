import { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { ACCESS_TOKEN_KEY, ErrorTypes, PROTO_SUPPORT_MAIL, validations } from '../shared/utils';
import { LoginType, deleteAccountRequest, getUserBasicRequest, googleLoginRequest, loginRequest } from '../shared/Requests';
import { useHistory } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const DeleteAccount = () => {
    const [loading, setLoading] = useState(false);
    const [wasAuthenticated, setWasAuthenticated] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [userId, setUserId] = useState<string>();

    const history = useHistory();

    const text = {
        explanation: [
            'מחיקת החשבון משתמש שלך באפליקציית Proto הינה פעולה סופית ולא ניתן לביטול.',
            'פרטי החשבון (למעט XYZ) יימחקו ולא ניתן יהיה לשחזרם.',
            'על-מנת למחוק את החשבון יש להזדהות באמצעות פרטי הכניסה שאיתם נרשמת.'
        ],
        title: 'היי, כאן מתחברים',
        email: 'דואר-אלקטרוני',
        password: 'סיסמא',
        noAccount: 'אין לך חשבון?',
        newUser: 'משתמש חדש',
        enter: 'כניסה',
        invalidMail: 'כתובת דוא"ל אינה תקינה',
        invalidPassword: 'הסיסמא שהוזנה אינה תקינה. יש להזין לפחות 8 תווים ביניהם לפחות ספרה אחת ולפחות אות אחת.',
        continue: 'המשך',
        retry: 'נסה שוב',
        errorOccured: 'חלה שגיאה',
        mismatch: 'כתובת דוא"ל או סיסמא לא נכונים',
        inactive: `משתמש לא פעיל. למידע נוסף ניתן ליצור קשר בכתובת דוא"ל:\n"${PROTO_SUPPORT_MAIL}"`,
        deletePrompt: 'האם למחוק את החשבון לצמיתות?',
        generalError: `חלה שגיאה בעת ניסיון מחיקת החשבון. יש לנסות שני מאוחר יותר.\nבמידה והבעיה עדיין קיימת, ניתן לפנות אלינו למייל: ${PROTO_SUPPORT_MAIL}`,
        googleAuthError: `חלה שגיאת אימות. יש לנסות שני מאוחר יותר.\nבמידה והבעיה עדיין קיימת, ניתן לפנות אלינו למייל: ${PROTO_SUPPORT_MAIL}`,
    };

    const inputsAreValid = () => {
        if (validations.isEmailValid(username) === false) {
            alert('כתובת דוא"ל אינה תקינה');
            return false;
        }
        if (validations.isPasswordValid(password) === false) {
            alert('הסיסמא שהוזנה אינה תקינה. יש להזין לפחות 8 תווים ביניהם לפחות ספרה אחת ולפחות אות אחת.');
            return false;
        }
        return true;
    }

    const login = async (accessToken: string) => {
        localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
        const { _id: userId } = await getUserBasicRequest();
        setUserId(userId);
        setWasAuthenticated(true);
    }

    const onSubmitButtonPress = async () => {
        if (inputsAreValid()) {
            setLoading(true);
            try {
                const userToken = await loginRequest({ username, password, loginType: LoginType.USERNAME_PASSWORD });
                if (userToken?.accessToken) {
                    login(userToken.accessToken);
                } else {
                    alert(text.mismatch);
                }

            }
            catch (err: any) {
                switch (err.toString()) {
                    case ErrorTypes.notFound:
                    case ErrorTypes.unauthorized:
                        alert(text.mismatch);
                        break;
                    case ErrorTypes.forbidden:
                        alert(text.inactive);
                        break;
                    default:
                        alert(text.errorOccured);
                }
            }
            finally {
                setLoading(false);
            }
        }
    }

    const onUsernameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setUsername(event.target.value);
    }
    const onPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setPassword(event.target.value);
    }

    const onDeleteAccountButtonPresss = async () => {
        try {
            if (userId) {
                await deleteAccountRequest(userId);
                alert('החשבון נמחק בהצלחה');
                history.replace('/');
            } else {
                alert(text.generalError);
            }
        } catch (err) {
            console.error('deleteAccountRequest failed:' + err);
            alert(text.generalError);
        }
    }

    const onGoogleAuthSuccess = async (token: string | undefined) => {
        if (token) {
            const { email, email_verified } = jwtDecode(token) as any;

            if (email_verified) {
                try {
                    const loginResponse = await googleLoginRequest(token, email);
                    const accessToken = loginResponse?.accessToken;
                    if (accessToken && accessToken.length > 0) {
                        login(accessToken);
                    }
                }
                catch (err) {
                    console.error('Auth error: ' + err);
                    alert(text.googleAuthError);
                }
            } else {
                console.error('GoogleLogin email is not verified');
                alert(text.googleAuthError);
            }
        } else {
            console.error('GoogleLogin error. No token.');
            alert(text.googleAuthError);
        }
    }

    return (
        <div className='Delete-account-container'>
            <div className='Delete-account-inner-container'>
                {wasAuthenticated === false ? <>
                    <div>
                        {text.explanation.map(t => <p className='Policy-text'>{t}</p>)}
                    </div>
                    <div className='delete-account-identification-container'>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <input className='credentials-field' type='text' onChange={onUsernameChange} placeholder='דוא"ל/שם-משתמש' />
                            <input className='credentials-field' type='password' onChange={onPasswordChange} placeholder='סיסמא' />
                            <button className='credentials-submit-button' onClick={onSubmitButtonPress}>הזדהה</button>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: 100, height: 2, marginTop: 2, backgroundColor: 'rgb(200,200,200)' }} />
                            <span style={{ padding: 16, color: 'white', fontFamily: 'Assistant' }}>או</span>
                            <div style={{ width: 100, height: 2, marginTop: 2, backgroundColor: 'rgb(200,200,200)' }} />
                        </div>
                        <div style={{ padding: 16 }}>
                            <GoogleLogin
                                onSuccess={credentialResponse => onGoogleAuthSuccess(credentialResponse?.credential)}
                                onError={() => {
                                    console.error('GoogleLogin onError');
                                    alert(text.googleAuthError);
                                }}
                                size='large'
                                text='continue_with'
                                locale='he_IL'
                                shape='circle'
                                width={220}
                            />
                        </div>
                    </div>
                </> :
                    <div className='delete-account-view-container'>
                        <p className='Policy-text'>{text.deletePrompt}</p>
                        <button className='delete-account-button' onClick={onDeleteAccountButtonPresss}>מחק לצמיתות</button>
                    </div>
                }
            </div >
        </div >
    );
}

export default DeleteAccount;

