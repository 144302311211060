export default {
    hebrew: {
        title: 'תקנון שימוש ותנאי פרטיות',
        clauses: [
            [
                'האמור בתקנון זה מתייחס באופן שווה לשני המינים, והשימוש בלשון זכר הוא מטעמי נוחות בלבד.',
                'גלישה באפליקציה או כניסה אליה או שימוש בשירותים המוצעים בה, מבטאת ומהווה הסכמה מצדך לתנאי תקנון זה, והם יחולו על כל פעולה שביצעת או תבצע באפליקציה. אם אינך מסכים לתנאי השימוש, כולם או חלקם, הנך מתבקש שלא לעשות כל שימוש באפליקציה.',
                '"PROTO ride together" שומר לעצמו את הזכות לעדכן ו/או למחוק מעת לעת את ההוראות בתקנון זה, לפי שיקול דעתו הבלעדי וללא צורך במסירת הודעה כלשהי מראש, ותנאים חדשים אלו יחייבו אותך מרגע פרסומם באפליקציה.',
            ],
            [
                '1. הגבלת שיח',
                'הנך מצהיר כי ידוע לך שחלק מהתכנים המפורסמים באפליקציה על-ידי משתמשים עלולים לכלול שפה בוטה. הנך מסכים בזאת כי תוכן מסוג זה אינו פוגע בך בשום דרך, צורה ואופן, והאחריות לשימוש וצפייה בחומרים באפליקציה חלים עליך בלבד. הנהלת האפליקציה שומרת לעצמה את הזכות להשהות או לבטל כל תגובהפרסום מנוי אשר יהווה הטרדה, פגיעה ו/או איום על מנויים.'
            ],
            [
                '2. הרשמה',
                'א. הנך מתבקש לנהוג בקפידה במסירת הפרטים ולמסור פרטים נכונים בלבד. בעת הרישום תתבקש לבחור אימייל וסיסמה. שם המשתמש והסיסמה ישמשו אותך בכל שימוש בשירותי האפליקציה ולצורך הזדהות. שמור על שם המשתמש והסיסמה בסודיות על מנת למנוע שימוש לרעה בהם. הקפד להחליף את הסיסמה בתדירות גבוהה ככל האפשר. אנו ממליצים להחליף את הסיסמה בתדירות גבוהה ככל האפשר. הליך הרישום וההזדהות יבוצע ויאושר בעזרת דואר אלקטרוני וסיסמא השייכים למשתמש ובאישורו.',
                'ב. באחריות המנוי לדווח אודות כל שימוש או חשד לשימוש לא מאושר במנוי ועל כל הפרת אבטחה אשר ידועה לו כולל אובדן, גניבה, או הפצת סיסמה ללא אישור. "PROTO" לא יישא באחריות לנזקים אשר ייווצרו כתוצאה משימוש לא מאושר במנוי, בשם משתמש או סיסמת המנוי.',
                'ג. מסיבות של פרטיות הגולשים הנהלת האפליקציה לא תוכל לשלוח את פרטי הכניסה לפרופיל שלך באפליקציה שלא באמצעות כתובת הדואר האלקטרוני המשויכת לפרופיל. אם אבדה הגישה לכתובת הדואר האלקטרוני שלך, עליך לעדכן את כתובת הדואר האלקטרוני לכתובת פעילה אחרת(בקישור "עדכון פרופיל").    *אם אבדה הסיסמא, האם צריך גם להחליף למייל חדש?'
            ],
            [
                '3. כללי שימוש',
                'א.מובהר בזאת כי על הגולש יחולו האיסורים הבאים:',
                '1. אין לעשות שימוש בתכנים בעלי אופי מאיים, גזעני או בוטה שעלול לפגוע ברגשות הציבור.',
                '2. אין להעלות או לפרסם תכנים העלולים להוות או המהווים הוצאת לשון הרע על אדם, פגיעה בפרטיותו או חדירה אליה, התייחסות למצבו הבריאותי של אדם, הטעיה, התחזות, הפרת זכויות יוצרים וקניין רוחני, פגיעה בזכויות חוזיות או הפרה אחרת של כל דין.',
                '3. אין להעיר הערות פוגעניות על או כלפי משתמשים אחרים.',
                '4. אין להציף את חדרי השיחה ואיזורי התגובות בהודעות חוזרות שפוגעות באפשרות לנהל דיון תקין באיזורים אלה.',
                '5. אין להעלות לאפליקציה חומר בלתי חוקי או חומר המעודד, או מסייע בביצוע, או מדריך לביצוע עבירה פלילית על פי חוקי מדינת ישראל.',
                '6. אין להתחזות לאדם או משתמש אחר, לפרסם פרטים אישיים על אדם לרבות פרסום שם אמיתי, מספר טלפון, כתובת או כל פרט אחר שיסייע בזיהויו.',
                '7. אין להיכנס, או לנסות להיכנס לחשבונות של משתמשים אחרים, לחדור או לנסות לעקוף את מנגנוני האבטחה של האפליקציה.',
                '8. אין להשתמש בשירותים או במידע אודות המיקום של משתמשים אחרים על מנת להטריד, לעקוב, להשמיץ, להונות או לאיים על משתמשים לרבות איסוף או אחסון מידע אישי עליהם.',
                '9. אין לבצע כל פרסום מסחרי באפליקציה שלא באמצעות "מנוי פרסומי" באפליקציה או באמצעות באנרים שהוצבו באפליקציה על ידי הנהלת האפליקציה.',
                '10. אין לבצע קישורים מהאפליקציה אל מקורות חיצוניים ללא קבלת אישור לכך מהנהלת האפליקציה.',
                '11. אין להפיץ את החומרים שבאפליקציה לרבות פרופילים של משתמשים בכל אמצעי אינטרנטי או מודפס ללא קבלת אישור לכך מהנהלת האפליקציה.',
                '12. אין לחדור לחומר ו / או קבצי מחשב שלא כדין ו / או להעביר תוכנת מחשב, קוד מחשב או יישומים הכוללים נגיף מחשב("וירוס"), לרבות תוכנות הידועות כסוס - טרויאני, תולעים(Worms), ואנדלים(Vandals) יישומים מזיקים(Malicious Applications) וכיו"ב ו/או להשתמש או להפריע למחשבים אחרים בניגוד לחוק המחשבים (התשנ"ה 1995).',
                '13. אין לפרסם חומר העלול להוות הטעיה צרכנית כמשמעות הדברים בחוק הגנת הצרכן(תשמ"א 1981).',
                'ב.הנהלת האפליקציה אינה אחראית בשום צורה ואופן לנזקים מכל סוג שהוא, לרבות הפסד כספי, אובדן מידע, הטרדות, פגיעה כתוצאה מהתכנים, וכל נזק ישיר ו / או עקיף שיגרם כתוצאה מהשימוש באפליקציה ו / או מהתנהגות הגולשים בו.'
            ],
            [
                '4. תכני גולשים',
                'א. כל תוכן שגולש העלה ו / או יעלה לאפליקציה(לרבות בכל אחד מהשירותים המוצעים באפליקציה), ובכלל זה(בין היתר) מילים, תמונות, וידאו, תכני שמע(קול), תוכנה, שילוב של וידיאו ותכני שמע, עיצוב, דף אינטרנט אישי(כהגדרתו להלן) או כל חומר אחר או תוכן שגולש מעלה לאפליקציה, הינו בבעלותו ובאחריותו הבלעדיות של הגולש שהעלה אותו.',
                'כל זכות(ככל שקיימת) בתכני גולש(בין היתר זכויות קניין רוחני מכל סוג שהוא), שייכות לגולש שהעלה את התוכן לאפליקציה(על פי הצהרתו בתקנון זה).לצורך השימוש בתכני הגולש יש לקבל את רשותו. שימוש בתכני גולש, ללא אישור ממנו, מהווה, בין היתר, עבירה על חוק זכות יוצרים(״חוק זכות יוצרים התשס”ח - 2007״).',
                'ב. הנך רשאי להעלות לאפליקציה כל תוכן, ובלבד שהוא עומד בתנאים הקבועים בתקנון זה. כאמור בתקנון זה, ל "PROTO ride together" הזכות להסיר תכנים שלך מהאפליקציה או לחסום את השתתפותך בו. אחריותך על התכנים שאתה מעלה לאפליקציה, תחול בין אם  "PROTO ride together" ניצל את זכותה ובין אם לא.',
                'כשאתה מעלה תכנים לאפליקציה אתה מצהיר שאתה הבעלים של כל הזכויות הרלוונטיות בהם לצורך עמידה בהוראות תקנון זה וכי אינך מפר כל דין או פוגעת בצד שלישי בהעלאתם או בפרסומם.',
                'למען הסר ספק ובנוסף לכל המפורט בתקנון האפליקציה, מובהר כי ל "PROTO ride together" אין כל אחריות לכל פרסום המתבצע באפליקציה על ידי צד שלישי, לרבות לתוכנו, לדיוק האמור בו ולהשלכותיו.',
                'במידה שתפר זכויות או דין מכל סוג שהוא או תפר הוראה כלשהי בתקנון זה, אתה מייפה את כוחו של  "PROTO ride together"  להסיר מעצמו כל אחריות ולהעביר אליך כל תביעה בנושא.',
                'ג. הנך מאשר כי כל חומר אשר תפרסם באפליקציה בין אם באזור ציבורי באפליקציה, בין אם בדף הפרופיל שלך, ובין אם בכל מקום גלוי אחר באפליקציה, יהיה בבעלות האפליקציה ואתה נותן בזה ל "PROTO ride together"  ללא תמורה הרשאה בלתי חוזרת לבצע כל פעולה בחומר לרבות פרסומו, שינויו, הסתרתו, מכירתו, הצגתו ברבים בכל דרך שהיא, הפצתו והעברתו לאחרים בכל דרך אחרת בין אם בתמורה אשר תשולם ל "PROTO ride together" ובין אם לאו.',
                'ד. הנך מאשר כי כל התמונות וקטעי הווידאו אשר תפרסם באפליקציה הינם שלך ובבעלותך.'
            ],
            [
                '5. זכויות יוצרים',
                'א. כל זכויות היוצרים והקניין הרוחני ב "PROTO ride together"  לרבות בעיצוב אפליקציה  "PROTO ride together"  ובכל תוכנה, יישום, קוד מחשב, קובץ גרפי, טקסט וכל חומר אחר הכלולים בו - הינן של  "PROTO ride together"  בלבד. אין להעתיק, להפיץ, להציג בפומבי, לתרגם או למסור לצד שלישי. כל חלק מן הנ"ל בלא קבלת הסכמתו המפורשת של  "PROTO ride together"  בכתב ומראש. שמו של  "PROTO ride together"  שם המתחם (Domain name) של האפליקציה וסימני המסחר (בין אם נרשמו ובין אם לאו) ב "PROTO ride together" הינם קניינו של " "PROTO ride together בלבד, או, במידה שפורסמו מטעמם של מפרסמים, קניינם של מפרסמים אלה בלבד. גם בהם אין לעשות שימוש בלא הסכמת  "PROTO ride together"  בכתב ומראש.'
            ],
            [
                '6. הפרת חוזה / הפסקת פעילות',
                'א. "PROTO ride together" רשאי, על פי שיקול דעתו הבלעדי וללא כל הודעה מוקדמת, למנוע ממך את השימוש באיזה מהשירותים ב "PROTO ride together", למנוע גישה לשירותים אלה, כולם או חלקם, לבטל תוקפו של שם משתמש וסיסמה מסוימים, לדרוש אימות תכנים, להסיר או למחוק כל מידע או תוכן ובכלל זה מידע שנמסר על ידך. " "PROTO ride together" יעשה כן בכל מקרה של הפרת תנאים אלה ו / או בכל מקרה של מעשה או מחדל הפוגע או העלול לפגוע בשירותים הניתנים ב "PROTO ride together", במשתמשיו, ב"PROTO" או מי מטעמו. הוראות סעיף זה מוסיפות על זכויות  "PROTO ride together"  על פי כל דין ועל - פי הוראות אחרות בתנאי שימוש אלה.',
                '"PROTO ride together" רשאי להפסיק בכל עת את מתן השירותים הניתנים ב "PROTO ride together"  ללא תשלום ו / או עם תשלום, כולם או מקצתם.',
                'ב. צוות שירות הלקוחות שלנו עומד לרשותך במתן תמיכה וסיוע כדי להפוך את חוויית הגלישה שלך למהנה. בכל פנייה לצוות שירות הלקוחות שלנו או לעובדים אחרים, בטלפון או בכל אמצעי תקשורת אחר, אתה מתחייב שלא לדבר בצורה פוגענית, בלתי צנועה, מאיימת או גזענית. במקרה שמי מעובדינו יחוש, בכל שלב שהוא, מאוים או נפגע מהתנהגותך, אנו שומרים את הזכות להפסיק את שימושך וחברותך לאלתר.'
            ],
            [
                '7. פרטיות',
                'א.פרטיות הגולשים באפליקציה מאוד חשובה לנו ואנו משקיעים מאמצים רבים בשמירה עליה.',
                'ב. אפליקציה  "PROTO ride together"  מחויב להוראות כל דין. במקרה שתפר את תנאי השימוש ב "PROTO ride together", את הוראות הסכם ההצטרפות לאפליקציה או איזה מהשירותים המוצעים בו או את תנאי השימוש באפליקציה, או אם תבצע באמצעות האפליקציה, או בקשר איתו, פעולות הנחזות כמנוגדות לדין, או ניסיון לבצע פעולות כאלה במקרים אלה, יהיה  "PROTO ride together"  רשאי למסור את פרטייך האישיים והמידע שנאסף על פעילותך באפליקציה (ככל שפרטים ומידע זה מזהים אותך אישית) לפי דרישת משטרה ו / או אם יינתן צו שיפוטי המורה ל "PROTO ride together" " למסור את פרטיך או המידע אודותיך לצד שלישי.'
            ],
            [
                '8. מיקום גיאוגרפי',
                'א. "PROTO ride together" " מספק שירותים, יישומים, מאפיינים, כלים, תכונות וכיוב’, התלויים, מבוססים או נסמכים על מידע ופרטי מיקום גיאוגרפי(“שירותים תלויי מיקום גיאוגרפי“) שנגזרים ממיקומו הגיאוגרפי של המכשיר הנייד ממנו נעשה שימוש באפליקציה, בשירותים, יישומים, מאפיינים, כלים, תכונות וכיוב’ של  "PROTO ride together"(“מיקום גיאוגרפי“).ככל שתסכים שייעשה שימוש בשירותים תלויי מיקום גיאוגרפי, הנך מסכים שנקבל שייאסף, יתקבל או יישמר מידע לגבי המיקום הגיאוגרפי שלך או של המכשיר הנייד ממנו נעשה השימוש בשירותים תלויי מיקום גיאוגרפי. ככל שלא תסכים ותאשר לעשות שימוש בשירותים תלויי מיקום גיאוגרפי, יתכן שלא תוכל לעשות שימוש בכל השירותים, יישומים, מאפיינים, כלים, תכונות וכיוב’ שמציע  "PROTO ride together"  ללקוחותיו. הרשמתך לאפליקציה מהווה הסכמה מצידך לאיסוף, שימוש, שמירה והעברת מידע לגבי מיקום גיאוגרפי וקבלת שירותים תלויי מיקום גיאוגרפי, אלא אם כן תתאים את ההגדרות או תנטרל את היישום של איסוף, שימוש והעברת המיקום הגיאוגרפי. רוב יצרני מכשירי הטלפון הניידים, מכשירי הלוח והמחשבים, או יצרני מערכות ההפעלה שלהם, מאפשרים לשנות את ההגדרות שלהם ולכבות את האפשרות לחלוקת מידע ועדכון לגבי המיקום הגיאוגרפי.'
            ],
            [
                '9. הגבלת אחריות',
                'א. כשאתה עושה שימוש באפליקציה (בין היתר גלישה באפליקציה, הפעלה של השירותים, אחסון וכל פעולה אחרת), אתה מוותר על כל תביעה, טענה ודרישה כנגד  "PROTO ride together", מי מספקיו או מי מטעמו.',
                'בפרט, אתה פוטר ומשחרר את  "PROTO ride together"  ו / או מי מטעמה באופן מוחלט ובלתי חוזר מאחריות כלשהי לכל נזק(ישיר או עקיף), הפסד, אבדן(לרבות אובדן רווח) או הוצאה מכל מן וסוג שהוא, דמי נזיקין עונשיים, דמי נזיקין בגין אובדן השימוש, או אובדן הנתונים(“פיצוי“), שנגרמו או ייגרמו לך או לצד שלישי והקשורים, במישרין או בעקיפין, לתכני גולשים המועלים או המופיעים באפליקציה (לרבות תכנים שאת העלית לאפליקציה), או לשירותים באפליקציה. כמו כן אתה פוטר את  "PROTO ride together"  ו / או מי מטעמה מאחריות לפיצוי כלשהו בגין עיכוב בשימוש או אי יכולת להשתמש באפליקציה או בגין אספקה של שירותים, או בגין כל מידע, תוכנה, מוצר, שירות וגרפיקה נלווית שהושגו באמצעות האפליקציה או הנובעים בכל דרך אחרת מן השימוש באפליקציה. כל זאת, בין בהתבסס על הסכם ובין בנזיקין, בין באחריות מוחלטת ובין בכל עילה אחרת, אפילו אם נודע ל "PROTO ride together"  או מי מטעמו על האפשרות של נזק כלשהו שעלול להיגרם.',
                'היעדר אחריותו של  "PROTO ride together"  ו / או מי מטעמה יחול אף אם הפעילה ו / או יכלה להפעיל פיקוח כלשהו ואף במצב בו נודעה לה האפשרות לנזק כלשהו, שעלול להיגרם לגולש או לצד שלישי כלשהו, וזאת בכל הקשור והנוגע לאפליקציה, לשימוש בו, לאי יכולת להשתמש בו, לביטול השירותים, הגבלתם או להפסקתם.',
                'היעדר אחריותו של  "PROTO ride together"  ו / או מי מטעמו יחול אף אם ניסה  "PROTO ride together"  מרצונו הטוב ולפנים משורת הדין, לסייע בפתרון בעיה או להיענות לפנייה של גולש. סיוע שכזה לא יתפרש בשום אופן כמטיל אחריות כלשהי על  "PROTO ride together"  ו / או מי מטעמו.'
            ],
            [
                '10. שיפוי',
                'א. הנך מתחייב לפצות ולשפות את  "PROTO ride together"  ו / או מי מטעמה בגין כל נזק, הפסד, אבדן או הוצאה, מכל מין וסוג שהוא (לרבות הוצאות משפטיות), שנגרמו או ייגרמו ל "PROTO ride together", הקשורים(במישרין או בעקיפין) לתכנים שהעלית או פרסמת באפליקציה ו / או בקשר להפרה שלך את הוראות תקנון זה או את הוראות הדין או פגיעה בצד שלישי כלשהו; וזאת מיד לאחר דרישתו הראשונה של  "PROTO ride together", וזאת בנוסף לכל זכות העומדת ל "PROTO ride together"  על פי כל דין.'
            ],
            [
                '11. שדרוג תוכנה',
                'א. "PROTO ride together"  עשוי לבצע שדרוגים ועדכונים באפליקציה האינטרנט או האפליקציה, אשר יעודכנו באופן אוטומטי במכשירך הנייד. הנך מאשר עדכון אוטומטי שכזה וכי ידוע לך שתנאי השימוש הנוכחיים חלים גם עליו.'
            ],
            [
                '12. פניות ותלונות',
                'א. מסיבות של פרטיות הגולשים הנהלת האפליקציה אינה מבקרת הודעות פרטיות שנשלחות בין גולשים ובתוך הרכיבות במסגרת האפליקציה. עם זאת המערכת מאפשרת להתלונן על גולשים שמפרים את תנאי השימוש באפליקציה באמצעות לחיצה ארוכה על תמונה הודעה המופיעה באפליקציה.',
                'ב. במידה שתבקש להתלונן על משתמש אחר, אופי האפליקציה ו / או במידה ותרצה לכתוב משוב כלשהו ו / או לשאול שאלה לגבי שירותי  "PROTO ride together", תוכל לבצע זאת על ידי משלוח אימייל לכתובת: contact-us@proto-ride-together.com .'
            ],
            [
                '13. שונות',
                'א. "PROTO ride together"  שומר את הזכות להגביל שימושים באפליקציה אשר ניתנים בחינם ולגבות עבורם תשלום בעתיד.',
                'ב. דיני מדינת ישראל יחולו על תקנון זה ועל כל הנובע ממנו או הכרוך בו, תקנון זה יפורש על פיהם, וכל סכסוך או שאלה משפטית בקשר אליו תובא להכרעה בערכאה השיפוטית המוסמכת.',
                'ג. הנהלת האפליקציה מבצעת מעת לעת מחיקות של פרופילים שאינם פעילים. ידוע לך כי אם הפרופיל שלך באפליקציה לא יהיה פעיל במשך תקופה של 6 חודשים ומעלה, תהיה הנהלת האפליקציה רשאית למחוק את הפרופיל על תכניו ולבטל את הגישה אליו.'
            ],
            [
                '14. שינויים בתקנון',
                '"PROTO ride together" רשאי לבצע שינויים בתקנון זה, כמו גם בכל אחד מההסכמים, התנאים, המחירים, התקנונים ההוראות וההנחיות שבאפליקציה ו/או של השירותים המסופקים על ידי "PROTO". זאת בכל עת, מכל סיבה שהיא ובהתאם לשיקול דעתו של "PROTO". השינוי ייכנס לתוקף בהתאם להוראה הקבועה בשינוי ביחס לתחילת תקפו ובהעדר הוראה כאמור - עם הפרסום באפליקציה של השינוי או של ההוראה העדכנית (למשל, במקרה של שינוי בתקנון – על ידי עצם פרסום התקנון המעודכן באפליקציה).'
            ],
            [
                '15. פציעות פיזיות וטעויות ניווט',
                'א. "PROTO ride together"  אינה אחראית על כל טעות ניווט שתיגרם למשתמש/ת תוך כדי שימוש באפליקציה.',
                'ב. "PROTO ride together"  מסירה כל אחריות ממנהלי הרכיבות באשר לפציעות או מקרה מוות או תאונות שיקרו באת ובזמן הרכיבה שהם הקימו. האחריות היא אך ורק על כל משתמש באשר הוא.',
                'ג. כל משתמש היוצא לרכיבה אחראי הוא לעצמו ולא יבוא בטענות והאשמות כלפי  "PROTO ride together"  בכל מקרה מוות ו/או ונפצע ו/או נפגע בתאונה בזמן שימוש באפליקציה.',
                'ד. כיוון הרכיבה בכל מסלול על אחריות המשתמש בלבד.',
                'ה. המסלולים המוצגים באפליקציה הינם מסלולים אשר משתמשים העלו ו/או ציירו ו/או הקליטו. ״PROTO ride together” אינה אחראית על שימוש במסלולים על ידי כל משתמש.'
            ],
            [
                'תודה לך על תשומת הלב. אנחנו מעריכים את בחירתך להשתמש ב"PROTO ride together" ומאחלים לך שימוש מהנה ומועיל.',
                'בברכה, צוות “PROTO ride together”'
            ]
        ]
    }
} as PolicyType;


type PolicyType = {
    hebrew: PolicyContentType,
};

type PolicyContentType = {
    title: string,
    clauses: Array<Array<string>>,
}
