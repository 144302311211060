import { useEffect } from 'react';
import { redirectToStore } from '../shared/utils';

const RedirectToStore = () => {
    useEffect(redirectToStore, []);
    return (
        <div />
    );
}

export default RedirectToStore;
