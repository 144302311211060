import { Link } from "react-router-dom";
import { isMobileDevice, redirectToStore } from "./utils";

const Header = () => {
    return (
        <nav className="App-header" style={{ flexDirection: 'row' }}>
            <Link to={'/'}>
                <div className="Header-link">ראשי</div>
            </Link>
            <Link to={'/policy'}>
                <div className="Header-link">מדיניות פרטיות</div>
            </Link>
            <Link to={'/delete_account'}>
                <div className="Header-link">מחיקת המשתמש שלי</div>
            </Link>
            {isMobileDevice() &&
                <Link to={'/'}>
                    <a className="Header-link" href="#" onClick={redirectToStore} >הורדה מהחנות</a>
                </Link>
            }
        </nav>
    );
}

export default Header;
