import React from 'react';
import policyText from '../shared/policy';

const Policy = () => {
    const { title, clauses } = policyText.hebrew;
    return (
        <div style={{ padding: '1vw 8vw' }}>
            <h1 className='Policy-title'>{title}</h1>
            {clauses.map((clause, key) => {
                if (key === 0 || key === clauses.length - 1) {
                    return <div key={key}>{clause.map((sentence, index) => index !== 0 && <p className='Policy-text'>{sentence}</p>)}</div>;
                }
                return (
                    <React.Fragment>
                        <p className='Policy-text' key={key} style={{ fontSize: 18, marginBottom: 8, marginTop: 32 }}>{clause[0]}</p>
                        <div>{clause.map((sentence, index) => index !== 0 && <li className='Policy-text' key={index}>{sentence}</li>)}</div>
                    </React.Fragment>
                )
            })}
        </div >
    );
}

export default Policy;
