import './App.css';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Home from './screens/Home';
import Policy from './screens/Policy';
import Header from './shared/Header';
import DownloadApk from './screens/Download_Apk';
import RedirectToStore from './screens/RedirectToStore';
import DeleteAccount from './screens/DeleteAccount';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CLIENT_ID } from './shared/utils';

const App = () => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        if (isLoggedIn === false) {
            const lastLoginTime = localStorage.getItem('rochvim_admin_login_time');
            const token = localStorage.getItem('rochvim_admin_token');
            if (token && token.length > 0 && lastLoginTime && (new Date(JSON.parse(lastLoginTime)).getTime() + 1 * 60 * 60 * 1000 > new Date().getTime())) {
                setIsLoggedIn(true);
                localStorage.setItem('rochvim_admin_login_time', JSON.stringify(new Date()));
            }
        }
    }, [isLoggedIn]);

    return (
        <GoogleOAuthProvider clientId={CLIENT_ID}>
            <BrowserRouter>
                <div>
                    <Header />
                    <Main />
                </div>
            </BrowserRouter>
        </GoogleOAuthProvider>
    );
}

const Main = () => {
    return (
        <main style={{ minHeight: '80vh' }}>
            <Route path="/" exact component={Home} />
            <Route path="/policy" component={Policy} />
            <Route path="/download_apk" component={DownloadApk} />
            <Route path="/get_app_from_store" component={RedirectToStore} />
            <Route path="/delete_account" component={DeleteAccount} />
        </main>
    )
}

export default App;
